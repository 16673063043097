import IcoMoon from '@/components/IcoMoon'
import Layout, { Stack } from '@/components/Layout'
import LottieImage, { LottieImageHandlers } from '@/components/LottieImage'
import MenuLink from '@/components/MenuLinks'
import { rbp } from '@/constants/measured-scope'
import { useAuth } from '@/context/auth'
import { BottomNavigationActiveTab, useNavigation } from '@/context/navigation'
import AuthMenu from '@/widgets/AuthMenu'
import BottomNavigation from '@/widgets/BottomNavigation'
import LanguagePicker from '@/widgets/LanguagePicker'
import SearchMenu from '@/widgets/SearchMenu'
import SliderMenu from '@/widgets/SliderMenu'
import UserMenu from '@/widgets/UserMenu'
import { useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as styles from './style'

const cx = classNames.bind(styles)

type ToggleMenus = 'nav' | 'search' | 'custom' | ''

interface NavigationLink {
  url?: string
  label: string
  hideForAuthenticated?: boolean
  openInNewTab: boolean
  path?: string
}

export interface Props {
  bottomNavigationActiveTab: BottomNavigationActiveTab
}

const PrimaryNavigation = ({ bottomNavigationActiveTab }: Props): JSX.Element => {
  const {
    state: { user },
  } = useAuth()
  const {
    state: { searchBox },
    dispatch,
  } = useNavigation()
  const lottieRef = useRef<LottieImageHandlers>(null)
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.lg})`, { initializeWithValue: false })
  const [menuVisible, setMenuVisible] = useState<ToggleMenus>('')
  const wrapperClasses = cx({
    wrapper: true,
  })

  function NavigationLinks(): NavigationLink[] {
    const { t } = useTranslation('common')
    const navigationLinks: NavigationLink[] = [
      { label: t('menu.host'), url: 'https://share.hsforms.com/1NendfH52RSCvpOFkCq8D1A49suz', openInNewTab: true },
      { label: t('menu.blog'), url: 'https://www.getsetup.com/blog-pages/learner-stories', openInNewTab: true },
      { label: t('menu.pricing'), path: `/pricing`, openInNewTab: false },
      { label: t('nav.help'), url: 'https://getsetup6054.zendesk.com/hc/en-us', openInNewTab: true },
    ]
    return navigationLinks
  }

  const menuLinks = NavigationLinks()

  const isAuthenticated = useMemo(() => {
    if (user) {
      return true
    }
    return false
  }, [user])

  useEffect(() => {
    if (!searchBox.open && menuVisible === 'search') setMenuVisible('')
  }, [searchBox.open])

  function toggleSearchMenu(): void {
    if (searchBox.open) {
      lottieRef.current?.playReverse()
      setMenuVisible('')
      dispatch({ type: 'SET_SEARCH_OPEN', payload: false })
    } else {
      lottieRef.current?.playForward()
      setMenuVisible('search')
      dispatch({ type: 'SET_SEARCH_OPEN', payload: true })
    }
  }

  function resetMenu(): void {
    lottieRef.current?.playReverse()
    setMenuVisible('')
    dispatch({ type: 'SET_SEARCH_OPEN', payload: false })
  }

  return isLargeDevice ? (
    <div className={wrapperClasses}>
      <Layout className={styles.headerContent}>
        <Stack x alignItems="center" justifyContent="space-between">
          <Link href="/" className={styles.logo} prefetch={false}>
            <img src="/images/logo-large.svg" alt="GetSetUp logo" loading="lazy" />
          </Link>
          <Stack className={styles.navMenu} x alignItems="center" justifyContent="flex-end">
            {menuLinks.map((link, i) =>
              link.hideForAuthenticated && isAuthenticated ? undefined : (
                <MenuLink
                  key={`menu-link-external-${i}`}
                  label={link.label}
                  url={link.url}
                  path={link.path}
                  openInNewTab={link.openInNewTab}
                />
              ),
            )}
            <LanguagePicker />

            {!isAuthenticated && <AuthMenu />}
            {isAuthenticated && <UserMenu firstName={user?.firstName} fullName={user?.fullName} />}
          </Stack>
        </Stack>
      </Layout>
    </div>
  ) : (
    <div className={wrapperClasses}>
      <Layout className={styles.headerContent}>
        <Stack className={styles.topBar} x alignItems="center" justifyContent="space-between">
          <span className={styles.menuButton} onClick={() => setMenuVisible('nav')}>
            <IcoMoon icon="menu" size={24} />
          </span>
          <Link href="/" className={styles.logo} prefetch={false}>
            <img src="/images/logo-touch.svg" alt="GetSetUp logo" loading="lazy" />
          </Link>
          <span className={styles.menuButton} onClick={toggleSearchMenu}>
            <LottieImage
              autoplay={false}
              className={styles.searchIcon}
              name="search-close-animation"
              path={'https://ddq2bomue87t4.cloudfront.net/lottie/search-close/search-close.json'}
              ref={lottieRef}
            />
          </span>
        </Stack>
      </Layout>
      <SearchMenu menuVisible={menuVisible === 'search'} />
      <SliderMenu close={resetMenu} menuVisible={menuVisible === 'nav'} />
      <BottomNavigation activeTab={bottomNavigationActiveTab} />
    </div>
  )
}
export default PrimaryNavigation
