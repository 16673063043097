import { rbp } from '@/constants/measured-scope'
import { useNavigation } from '@/context/navigation'
import { useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import Link from 'next/link'
import * as styles from './style'

const cx = classNames.bind(styles)

export interface Props {
  active?: boolean
  blip?: boolean
  className?: string
  label: string
  url: string
}

const MenuLinkInternal = ({ active = false, blip = false, className = '', label, url }: Props): JSX.Element => {
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.tablet})`, { initializeWithValue: false })
  const { dispatch } = useNavigation()

  const classes = cx({
    link: true,
    [`${className}`]: true,
  })

  return (
    <div className={styles.root}>
      <Link href={url} passHref legacyBehavior>
        <a
          data-testid="menu-link-internal"
          href={url}
          className={classes}
          title={label}
          onClick={() => dispatch({ type: 'SET_LEARN_URL', payload: url })}
        >
          {label}
          {isLargeDevice && active && <span className={styles.active} />}
        </a>
      </Link>
      {!isLargeDevice && active && <span className={styles.active} />}
      {blip && <span className={styles.blip} />}
    </div>
  )
}
export default MenuLinkInternal
